import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { debounce } from "lodash";
import moment from "moment";

import NavigationBar from "../Navbar";
import HomePageHeader from "../molecule/homepage/HomePageHeader/HomePageHeader";
import HomePageFooter from "../molecule/homepage/HomePageFooter/HomePageFooter";

import HorizontalChart from "../General/HorizontalChart/HorizontalChart";
import Chat from "../General/Chat/Chat";

import GameInfo from "./components/GameInfo/GameInfo";
import GameOverview from "./components/GameOverview/GameOverview";
import GameYoutubeVideo from "./components/GameYoutubeVideo/GameYoutubeVideo";
import GamePredictions from "./components/GamePredictions/GamePredictions";
import TopGamePredictions from "./components/TopGamePredictions/TopGamePredictions";
import GameVote from "./components/GameVote/GameVote";
import ErrorPage from "../../components/molecule/ErrorPage/ErrorPage";
import apiService from "../../services/apiService";

import "./GameDetail.css";

const GameDetail = () => {
  const currentPageUrl = window.location.href;

  const { t, i18n } = useTranslation();
  const location = useLocation();
  const isSmallLaptop = useMediaQuery({ maxWidth: 1280 });
  const isTablet = useMediaQuery({ maxWidth: 1024 });

  const [isChatFullScreent, setIsChatFullScreent] = useState(false);
  const mainContainerRef = useRef(null);
  const additionalContainerRef = useRef(null);
  const overviewRef = useRef(null);
  const videoOverviewRef = useRef(null);
  const predictionsRef = useRef(null);
  const analyticsRef = useRef(null);
  const chatRef = useRef(null);
  const gameInfoMobileRef = useRef(null);
  const tabsRef = useRef(null);

  const currentPath = location.pathname;
  const pathParts = currentPath.split("/");
  const gameId = pathParts[pathParts.length - 1];

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isScrolled, setIsScrolled] = useState(false);
  const [gameDetails, setGameDetails] = useState({});
  const [gameAPIDetails, setGameAPIDetails] = useState({});
  const [gameStatictic, setGameStatistic] = useState({});
  const [gameVideo, setGameVideo] = useState({});
  const [gameChartInfo, setGameChartInfo] = useState({});
  const [gameTopPredictions, setGameTopPredictions] = useState({});

  const [activeTab, setActiveTab] = useState("overview");

  const user = JSON.parse(localStorage.getItem("profile"));

  const getGameDetails = async () => {
    const res = await apiService.getGameDetails(gameId);

    if (res) {
      if (res.error) {
        setError(res.error);
      } else {
        setGameDetails(res.gameDetail);
        setGameAPIDetails(res.apiGameDetail);
      }
    }
  };

  const getGameStatistic = async () => {
    const res = await apiService.getGameStatistic(gameId);
    if (res) {
      setGameStatistic(res);
    }
  };

  const getGameVideo = async () => {
    const res = await apiService.getGameVideo(gameId);
    if (res) {
      setGameVideo(res);
    }
  };

  const getGameChartInfo = async () => {
    const res = await apiService.getGameChartInfo(gameId);
    if (res) {
      setGameChartInfo(res);
    }
  };

  const getGameTopPredictions = async () => {
    const res = await apiService.getGameTopPredictions(gameId);
    if (res) {
      setGameTopPredictions(res);
    }
  };

  useEffect(() => {
    getGameDetails();
    getGameStatistic();
    getGameVideo();
    getGameChartInfo();
    getGameTopPredictions();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      console.log("loading");
      getGameDetails();
      getGameStatistic();
      getGameVideo();
      getGameChartInfo();
      getGameTopPredictions();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  const handleActiveTab = (tabName) => {
    setActiveTab(tabName);

    const container = isTablet
      ? mainContainerRef.current
      : additionalContainerRef.current;

    const padding = isTablet ? 150 : 10;

    if (container) {
      const refs = {
        overview: overviewRef,
        chat: chatRef,
        predictions: predictionsRef,
        analytics: analyticsRef,
        videoOverview: videoOverviewRef,
      };

      const selectedRef = refs[tabName];

      if (selectedRef && selectedRef.current) {
        const offset = selectedRef.current.offsetTop - container.offsetTop;

        container.scrollTo({
          top: offset - padding,
          behavior: "smooth",
        });
      }
    }
  };

  const handleScroll = () => {
    const tabsRect = tabsRef.current.getBoundingClientRect();
    const containerRect = mainContainerRef.current.getBoundingClientRect();

    const relativePosition = tabsRect.top - containerRect.top;

    if (relativePosition < 71) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }

    handleActiveTabByScroll(mainContainerRef);
  };

  const handleDesktopScroll = () => {
    handleActiveTabByScroll(additionalContainerRef);
  };

  const handleActiveTabByScroll = (mainContainer) => {
    const minRectTopPosition = 150;
    const containerRect = mainContainer.current.getBoundingClientRect();
    const sections = [
      { ref: overviewRef, name: "overview" },
      { ref: videoOverviewRef, name: "videoOverview" },
      { ref: predictionsRef, name: "predictions" },
      { ref: analyticsRef, name: "analytics" },
      { ref: chatRef, name: "chat" },
    ];

    const activeSection = sections.find(({ ref, name }) => {
      const refCurrent = ref.current;

      if (!refCurrent) return false;

      const rect = refCurrent.getBoundingClientRect();
      const position = rect.top - containerRect.top;
      const isActive =
        position < minRectTopPosition &&
        position > -(rect.height - minRectTopPosition);

      return isActive;
    });

    if (activeSection) {
      setActiveTab(activeSection.name);
    }
  };

  const getScore = (team) => {
    if (gameDetails?.gameStatus === "line") {
      return "-";
    }

    if (gameAPIDetails.score_full) {
      let scores = gameAPIDetails.score_full.split(":");

      return scores[team - 1];
    }

    if (gameDetails?.result?.score) {
      let scoreStr = gameDetails?.result?.score.split("(")[0];
      let scores = scoreStr.split(":");

      return scores[team - 1];
    }

    return "-";
  };

  const getPageTitle = () => {
    const language = localStorage.getItem("language");

    const {
      gameStatus,
      opp_1_name,
      opp_2_name,
      opp_1_name_en,
      opp_2_name_en,
      game_start,
    } = gameDetails;

    let scoreStr = "vs";

    if (gameStatus !== "line") {
      scoreStr = getScore(1) + "-" + getScore(2);
    }

    const teams =
      language === "en"
        ? `${opp_1_name_en} ${scoreStr} ${opp_2_name_en}`
        : `${opp_1_name} ${scoreStr} ${opp_2_name}`;

    const formattedDate = moment.unix(game_start).format("D.M.YYYY");

    const video =
      gameVideo.gameId &&
      (language === "ua" ? ", Відеоогляд" : ", Video Review");

    return t("gameDetail.pageTitle", {
      teams,
      date: formattedDate,
      video: video || "",
    });
  };

  const getPageDescription = () => {
    const language = localStorage.getItem("language") || "ua";

    const {
      game_start,
      opp_1_name,
      opp_2_name,
      opp_1_name_en,
      opp_2_name_en,
      tournament_name,
      tournament_name_en,
    } = gameDetails;

    const teams =
      language === "en"
        ? `${opp_1_name_en} vs ${opp_2_name_en}`
        : `${opp_1_name} - ${opp_2_name}`;

    const tournament = language === "en" ? tournament_name_en : tournament_name;

    moment.locale(language === "ua" ? "uk" : language);

    const formattedDate = moment
      .unix(game_start)
      .format(
        language === "en"
          ? "MMMM D, YYYY, [at] HH:mm"
          : "D MMMM YYYY [року в] HH:mm"
      );

    return t("gameDetail.pageDescription", {
      teams,
      date: formattedDate,
      tournament,
    });
  };

  const getPageH1Title = () => {
    const language = localStorage.getItem("language") || "ua";

    const { opp_1_name, opp_2_name, opp_1_name_en, opp_2_name_en } =
      gameDetails;

    const teams =
      language === "en"
        ? `${opp_1_name_en} vs ${opp_2_name_en}`
        : `${opp_1_name} vs ${opp_2_name}`;

    const video =
      gameVideo.gameId &&
      (language === "ua" ? ", відеоогляд на матч" : ", Video Review");

    return t("gameDetail.pageH1Title", {
      teams,
      video,
    });
  };

  return (
    <>
      {error ? (
        <>
          <Helmet>
            <title>Not Found</title>
          </Helmet>
          <ErrorPage />
        </>
      ) : (
        <div>
          {gameDetails.game_id && (
            <Helmet>
              <title>{getPageTitle()}</title>
              <meta name="description" content={getPageDescription()} />

              <meta name="og:url" content={currentPageUrl} />
              <meta name="og:title" content={getPageTitle()} />
              <meta name="og:description" content={getPageDescription()} />

              <meta name="twitter:title" content={getPageTitle()} />
              <meta name="twitter:description" content={getPageDescription()} />

              <link rel="canonical" href={currentPageUrl} />

              <link
                rel="alternate"
                href={`https://capperauditor.com/predictions/${
                  gameDetails.sgame_id || gameDetails.game_id
                }`}
                hreflang="uk"
              />
              <link
                rel="alternate"
                href={`https://capperauditor.com/en/predictions/${
                  gameDetails.sgame_id || gameDetails.game_id
                }`}
                hreflang="en"
              />
              <link
                rel="alternate"
                href={`https://capperauditor.com/predictions/${
                  gameDetails.sgame_id || gameDetails.game_id
                }`}
                hreflang="x-default"
              />
            </Helmet>
          )}

          {localStorage.getItem("token") && localStorage.getItem("profile") ? (
            <NavigationBar countData={1} />
          ) : (
            <HomePageHeader />
          )}
          <div className="game-details__main-section">
            <div className="game-details__left-box">
              <GameInfo
                gameId={gameId}
                gameDetails={gameDetails}
                isLoading={isLoading}
                gameAPIDetails={gameAPIDetails}
                getScore={getScore}
              />
            </div>

            {isChatFullScreent && isSmallLaptop ? (
              <div className="full-screen__chat">
                <div className="full-screen__chat-title">
                  <img
                    src="/gameDetail/back.svg"
                    onClick={() => setIsChatFullScreent(false)}
                    alt="back"
                  />
                  {t("gameDetail.chat")}
                </div>
                <div className="full-screen__chat-section">
                  <Chat
                    gameId={gameId}
                    user={user}
                    setIsChatFullScreent={setIsChatFullScreent}
                    isChatFullScreent={isChatFullScreent}
                  />
                </div>
              </div>
            ) : (
              <div
                className="game-details__central-box"
                ref={mainContainerRef}
                onScroll={handleScroll}
              >
                {isTablet && (
                  <GameInfo
                    gameId={gameId}
                    gameDetails={gameDetails}
                    gameAPIDetails={gameAPIDetails}
                    isLoading={isLoading}
                    isScrolled={isScrolled}
                    gameInfoMobileRef={gameInfoMobileRef}
                    getScore={getScore}
                  />
                )}

                <div className="game-detail__tabs" ref={tabsRef}>
                  <div
                    className={
                      activeTab === "overview"
                        ? "game-detail__tab active"
                        : "game-detail__tab"
                    }
                    onClick={() => handleActiveTab("overview")}
                  >
                    {t("gameDetail.overView")}
                  </div>
                  {(user?.role.includes("Admin") || gameVideo.link) && (
                    <div
                      className={
                        activeTab === "videoOverview"
                          ? "game-detail__tab active"
                          : "game-detail__tab"
                      }
                      onClick={() => handleActiveTab("videoOverview")}
                    >
                      {t("gameDetail.videoOverView")}
                    </div>
                  )}
                  {isSmallLaptop && (
                    <div
                      className={
                        activeTab === "chat"
                          ? "game-detail__tab active"
                          : "game-detail__tab"
                      }
                      onClick={() => handleActiveTab("chat")}
                    >
                      {t("gameDetail.chat")}
                    </div>
                  )}
                  <div
                    className={
                      activeTab === "predictions"
                        ? "game-detail__tab active"
                        : "game-detail__tab"
                    }
                    onClick={() => handleActiveTab("predictions")}
                  >
                    {t("gameDetail.predictions")}
                  </div>
                  <div
                    className={
                      activeTab === "analytics"
                        ? "game-detail__tab active"
                        : "game-detail__tab"
                    }
                    onClick={() => handleActiveTab("analytics")}
                  >
                    {t("gameDetail.analytics")}
                  </div>
                </div>
                {/* </div> */}
                <div
                  className="game-details__content"
                  ref={additionalContainerRef}
                  onScroll={handleDesktopScroll}
                >
                  {isTablet && (
                    <div className="game-details__content-section">
                      <GameVote
                        gameId={gameId}
                        gameAPIDetails={gameAPIDetails}
                        gameDetails={gameDetails}
                      />
                    </div>
                  )}
                  <div
                    className="game-details__content-section"
                    ref={overviewRef}
                  >
                    <div className="game-details__content-title">
                      {t("gameDetail.overView")}
                    </div>
                    <div className="game-details__content-wrapper game-details__overview-wrapper">
                      <GameOverview gameStatictic={gameStatictic} />
                    </div>
                  </div>
                  {(user?.role.includes("Admin") || gameVideo.link) && (
                    <div
                      className="game-details__content-section"
                      ref={videoOverviewRef}
                    >
                      <GameYoutubeVideo
                        gameId={gameId}
                        gameVideo={gameVideo}
                        setGameVideo={setGameVideo}
                      />
                    </div>
                  )}
                  {isSmallLaptop && (
                    <div
                      className="game-details__content-section game-details__chat-section"
                      ref={chatRef}
                    >
                      <div className="game-details__content-title">
                        {t("gameDetail.chat")}
                      </div>
                      <div className="game-details__content-wrapper chat_content-wrapper">
                        <Chat
                          gameId={gameId}
                          user={user}
                          setIsChatFullScreent={setIsChatFullScreent}
                          isChatFullScreent={isChatFullScreent}
                        />
                      </div>
                    </div>
                  )}

                  <div
                    className="game-details__content-section"
                    ref={predictionsRef}
                  >
                    <div className="game-details__content-title">
                      {t("gameDetail.predictions")}
                    </div>
                    <div className="game-details__content-wrapper">
                      <GamePredictions
                        gameStatictic={gameStatictic}
                        isLoading={isLoading}
                      />
                      <div className="chart-delimeter"></div>
                      <TopGamePredictions
                        data={gameTopPredictions}
                        isLoading={isLoading}
                      />
                    </div>
                  </div>
                  <div
                    className="game-details__content-section"
                    ref={analyticsRef}
                  >
                    <div className="game-details__content-title">
                      {t("gameDetail.analytics")}
                    </div>
                    <div className="game-details__content-wrapper">
                      <HorizontalChart
                        title={t("gameDetail.chartTitle1")}
                        chartData={gameChartInfo.coefChart}
                        isLoading={isLoading}
                      />
                      <div className="chart-delimeter"></div>
                      <HorizontalChart
                        title={t("gameDetail.chartTitle2")}
                        titleIcon="/CAP coin.png"
                        chartData={gameChartInfo.coinsChart}
                        isLoading={isLoading}
                        isCoins={true}
                      />
                    </div>
                  </div>

                  <div className="bottom-h1-section">
                    <h1>{getPageH1Title()}</h1>
                  </div>
                </div>
              </div>
            )}

            {!isSmallLaptop && (
              <div className="game-details__chat">
                <div className="game-details__chat-title">
                  <img src="/gameDetail/chat.svg" alt="chat"></img>
                  {t("gameDetail.chat")}
                </div>
                <Chat gameId={gameId} user={user} isChatFullScreent={true} />
                {/* <div className="comming">Comming soon...</div> */}
              </div>
            )}
          </div>
          <HomePageFooter />
        </div>
      )}
    </>
  );
};

export default GameDetail;
